@media (max-width: 767px){    
    .contact {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 20%;
        background-color: #f7f7ff;
      }
      
    
    .contact-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90vw;
        padding: 4%;
        border-radius: 20px;
        margin-bottom: 10%;
    }
    
    .ContactTitlecontainer{
        color: #2B2E30;
        text-align: center;
        margin-top: 0%;
    }
    
    .ContactTitlecontainer h2{
        margin-bottom: 10%;
        letter-spacing: 1px;
        font-size: 8vw;
        font-weight: 800;        
        font-family: 'RoundedMplus1c', sans-serif;
    }
    
    .ContactTitlecontainer span{
        color: #001CAA;
    }
    
    .ContactTitlecontainer p{
        margin: 0 5%;
        font-size: 4.5vw;
        font-weight: 600;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 10%;
    }
    
    .button-submition{
        height: 4.5vh;
        margin-top: 4%;
        float: right;
        background-color: #001CAA;
        border: 3px solid #001CAA;
        border-radius: 10px;
        padding: 1.5% 2.5%;
        color: white;
        font-weight: bold;
        font-size: 3.5vw;
        letter-spacing: 1.5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .button-submition:hover{
        background-color: rgb(255, 255, 255);
        border: 3px solid #001CAA;
        color: #001CAA;
    }

    .button-Loading{
        height: 4.5vh;
        margin-top: 4%;
        float: right;
        background-color: #001CAA;
        border: 3px solid #001CAA;
        border-radius: 10px;
        padding: 1.5% 2.5%;
        color: white;
        font-weight: bold;
        font-size: 3.5vw;
        letter-spacing: 1.5px;
    }
    
    .form-contact{
        margin-top: 10%;
        text-align: left;
    }
    
    .input-email{
        width: 100%;
        padding-top: 1%;
        padding-bottom: 1.5%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        margin-bottom: 5%;
        border-radius: 10px;
        border: 2px rgb(170, 170, 170) solid;
        background-color: #f3f4ff;
    }
    
    .textarea-m{
        width: 100%;
        height: 30vh !important;
        padding-top: 1%;
        padding-bottom: 2.5%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        border-radius: 10px;
        border: 2px rgb(170, 170, 170) solid;
        overflow-wrap: break-word;
        resize: none;
        overflow: hidden;
        background-color: #f3f4ff;
    }

    .Footercontact{
        padding: 8% 2%;
        background-color: #012238;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .Info-footer-Container{
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .Phone-Ubication-container{
        width: 100%;
        text-align: center;
        margin-bottom: 5%;
    }

    .Info-footer-Container p{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0% 2%;
        height: 4vh;
    }

    .Info-footer-Container p:hover{
        text-decoration: underline;
        cursor: pointer;
    }

    .Etiqueta-A{
        margin-top: 5%;
        display: flex;
        justify-content: left;
        align-items: center;
        color: #ffffff;
        text-decoration: none;
        padding: 0% 2%;
        height: 4vh;
        text-decoration: underline;
    }

    .Etiqueta-A:hover{
        color: #ffffff;
        text-decoration: underline;
        cursor: pointer;
    }

    .Info-footer-Container a:hover{
        color: #ffffff;
        text-decoration: underline;
        cursor: pointer;
    }

    .Horario-Container{
        width: 100%;
    }

    .Horario-footer:hover{
        text-decoration: none !important;
        cursor:default !important;
    }

    .icon-container-footer{
        color: white;
        display: flex;
        justify-content: center;
        margin-top: 10%;
        width: 100%;
    }

    .Icons-Container{
        width: 30%;
        display: flex;
        justify-content: space-between;
        font-size: 8.5vw;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1023px){

    .contact {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 15%;
        background-color: #f7f7ff;
      }
      
    
    .contact-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80vw;
        padding-top: 5%;
        padding-bottom: 5%;
    }
    
    .ContactTitlecontainer{
        color: #2B2E30;
        text-align: center;
        margin-top: 0%;
    }
    
    .ContactTitlecontainer h2{
        margin-bottom: 10%;
        letter-spacing: 1px;
        font-size: 5vw;
        font-weight: 800;        
        font-family: 'RoundedMplus1c', sans-serif;
    }
    
    .ContactTitlecontainer span{
        color: #001CAA;
    }
    
    .ContactTitlecontainer p{
        margin: 0 5%;
        font-size: 2.1vw;
        font-weight: 600;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 10%;
    }
    
    .button-submition{
        height: 4.5vh;
        margin-top: 4%;
        float: right;
        background-color: #001CAA;
        border: 3px solid #001CAA;
        border-radius: 15px;
        padding: 1.5% 2.5%;
        color: white;
        font-weight: bold;
        font-size: 2.1vw;
        letter-spacing: 1.5px;
    }
    
    .button-submition:hover{
        background-color: rgb(255, 255, 255);
        border: 3px solid #001CAA;
        color: #001CAA;
    }

    .button-Loading{
        height: 4.5vh;
        margin-top: 4%;
        float: right;
        background-color: #001CAA;
        border: 3px solid #001CAA;
        border-radius: 15px;
        padding: 1.5% 2.5%;
        color: white;
        font-weight: bold;
        font-size: 1.5vw;
        letter-spacing: 1.5px;
    }
    
    .form-contact{
        margin-top: 10%;
        text-align: left;
    }
    
    .input-email{
        width: 100%;
        padding-top: 1%;
        padding-bottom: 1.5%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        margin-bottom: 5%;
        border-radius: 15px;
        border: 2px rgb(170, 170, 170) solid;
        background-color: #f3f4ff;
    }
    
    .textarea-m{
        width: 100%;
        height: 30vh !important;
        padding-top: 1%;
        padding-bottom: 2.5%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        border-radius: 15px;
        border: 2px rgb(170, 170, 170) solid;
        overflow-wrap: break-word;
        resize: none;
        overflow: hidden;
        background-color: #f3f4ff;
    }

    .Footercontact{
        padding: 4% 2%;
        background-color: #012238;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .Info-footer-Container{
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        text-align: center;
        width: 100%;
    }

    .Phone-Ubication-container{
        width: 100%;
        text-align: center;
        margin-bottom: 2%;
    }

    .Info-footer-Container p{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4vh;
        font-size: 2vw;
    }

    .Info-footer-Container p:hover{
        text-decoration: underline;
        cursor: pointer;
    }

    .Etiqueta-A{
        display: flex;
        justify-content: left;
        align-items: center;
        color: #ffffff;
        text-decoration: none;
        padding: 0% 2%;
        height: 4vh;
        text-decoration: underline;
        font-size: 2vw;
    }

    .Etiqueta-A:hover{
        color: #ffffff;
        text-decoration: underline;
        cursor: pointer;
    }

    .Horario-Container{
        width: 100%;
    }

    .Horario-footer:hover{
        text-decoration: none !important;
        cursor:default !important;
    }

    .icon-container-footer{
        color: white;
        display: flex;
        justify-content: center;
        margin-top: 5%;
        width: 100%;
    }

    .Icons-Container{
        width: 15%;
        display: flex;
        justify-content: space-between;
        font-size: 4vw;
    }

}

@media (min-width: 1024px) {
    
    .contact {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        padding-top: 8%;
        background-color: #f7f7ff;
      }
      

    .contact-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3%;
        margin-bottom: 5%;
        margin-left: 2%;
        margin-right: 5.5%;
    }
    
    .ContactTitlecontainer{
        color: #2B2E30;
        text-align: left;
        width: 65%;
        margin-left: 10%;
        margin-right: 5%;
        margin-top: 2%;
    }

    .ContactTitlecontainer h2{
        margin-bottom: 4%;
        letter-spacing: 1px;
        font-size: 3vw;
        font-weight: 800;        
      font-family: 'RoundedMplus1c', sans-serif;
    }

    .ContactTitlecontainer span{
        color: #001CAA;
    }

    .ContactTitlecontainer p{
        font-size: 1.2vw;
        font-weight: 600;
        letter-spacing: 1px;
        text-align: left;
        margin-left: 0;
    }

    .submitbutton-pos{

    }

    .button-submition{
        margin-top: 4%;
        float: right;
        background-color: #001CAA;
        border: 3px solid #001CAA;
        border-radius: 15px;
        padding: 1.5% 2.5%;
        color: white;
        font-weight: bold;
        font-size: 1.1vw;
        letter-spacing: 1.5px;
    }

    .button-submition:hover{
        background-color: rgba(255, 255, 255, 0);
        border: 3px solid #001CAA;
        color: #001CAA;
    }

    .button-Loading{
        margin-top: 4%;
        float: right;
        background-color: #001CAA;
        border: 3px solid #001CAA;
        border-radius: 15px;
        padding: 1.5% 2.5%;
        color: white;
        font-weight: bold;
        font-size: 1.1vw;
        letter-spacing: 1.5px;
    }

    .form-contact{
        margin-right: 2%;
        margin-top: 4%;
        text-align: left;
    }

    .input-email{
        width: 100%;
        padding-top: 2%;
        padding-bottom: 2.5%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        margin-bottom: 5%;
        border-radius: 15px;
        border: 2px rgb(170, 170, 170) solid;
        overflow: hidden;
        background-color: #f7f7ff;
    }

    .textarea-m {
        width: 100%;
        height: 30vh !important;
        padding-top: 2%;
        padding-left: 3%;
        padding-right: 5%;
        outline: none;
        border-radius: 15px;
        border: 2px rgb(170, 170, 170) solid;
        overflow-wrap: break-word;
        resize: none;
        overflow: hidden;
        background-color: #f7f7ff;
    }
    

    .Footercontact{
        padding: 4% 2%;
        background-color: #012238;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Info-footer-Container{
        color: white;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .Phone-Ubication-container{
        width: 40%;
        text-align: left;
    }

    .Info-footer-Container p{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: left;
        margin: 0 2%;
        height: 4vh;
        font-weight: 500;
        font-size: 1.2vw;
    }

    .Info-footer-Container p:hover{
        text-decoration: underline;
        cursor: pointer;
    }

    .Etiqueta-A{
        display: flex;
        justify-content: left;
        align-items: center;
        color: #ffffff;
        text-decoration: none;
        padding: 0% 2%;
        height: 4vh;
        text-decoration: underline;
        font-weight: 500;
        font-size: 1.2vw;
    }

    .Etiqueta-A:hover{
        color: #ffffff;
        text-decoration: underline;
        cursor: pointer;
    }

    .Horario-Container{
        width: 30%;
    }

    .Horario-footer:hover{
        text-decoration: none !important;
        cursor:default !important;
    }

    .icon-container-footer{
        color: white;
        display: flex;
        justify-content: right;
        margin-top: 3%;
        margin-right: 5%;
        width: 100%;
    }

    .Icons-Container{
        width: 8%;
        display: flex;
        justify-content: space-between;
        font-size: 2.3vw;
    }
}