
@media (max-width: 767px) {
    
    .Rolecard-container{
        width: 90%;
        min-height: 45vh;
        padding: 1% 5%;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
    }

    .Rolecard-information {
        text-align: left;
        border-bottom: 1px solid rgb(201, 201, 201);
    }
     
    .executives-title {
        font-size: 4.5vw;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .Cars-Image{
        width: 100%;
        cursor: pointer;
    }

    .Rolecard-information h5{
        font-size: 3.7vw;
        font-weight: 500;
        text-align: left;
    }

    .Precio-New-Card {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgb(201, 201, 201);
        padding: 2% 0%;
    }
    
    .Precio-New-Card p {
        font-size: 6vw;
        font-weight: 600;
        color: #2B2E30;
        margin: 0;
    }
    
    .Button-New-Card{
        margin-top: 0%;
        padding-top: 10%;
    }

    .Button-New-Card button {
        float: right;
        border: none;
        background-color: transparent;
        color: #001CAA;
        letter-spacing: 1px;
        font-size: 4vw;
        font-weight: 600;
    }

    .Button-New-Card button:hover{
        color: #0b1b68;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1023px){

    .Rolecard-container{
        width: 90%;
        min-height: 36vh;
        padding: 5%;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
    }

    .Rolecard-information {
        text-align: left;
        border-bottom: 1px solid rgb(201, 201, 201);
    }
     
    .executives-title {
        font-size: 2.5vw;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .Cars-Image{
        width: 100%;
        cursor: pointer;
    }

    .Rolecard-information h5{
        font-size: 2.3vw;
        font-weight: 500;
        text-align: left;
    }

    .Precio-New-Card {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgb(201, 201, 201);
        padding: 2% 0%;
    }
    
    .Precio-New-Card p {
        font-size: 3vw;
        font-weight: 600;
        color: #2B2E30;
        margin: 0;
    }
    
    .Button-New-Card{
        margin-top: 10%;
    }

    .Button-New-Card button {
        float: right;
        border: none;
        background-color: transparent;
        color: #001CAA;
        letter-spacing: 1px;
        font-size: 2.2vw;
        font-weight: 600;
    }

    .Button-New-Card button:hover{
        color: #0b1b68;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
    }

}

@media (min-width: 1024px) {
    
    .Rolecard-container{
        width: 20vw;
        min-height: 50vh;
        padding: 5%;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
    }

    .Rolecard-information {
        text-align: left;
        border-bottom: 1px solid rgb(201, 201, 201);
    }
     
    .executives-title {
        font-size: 1.3vw;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .Cars-Image{
        width: 100%;
        cursor: pointer;
    }

    .Rolecard-information h5{
        font-size: 1.1vw;
        font-weight: 500;
        text-align: left;
    }

    .Precio-New-Card {
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgb(201, 201, 201);
        padding: 2% 0%;
    }
    
    .Precio-New-Card p {
        font-size: 1.5vw;
        font-weight: 600;
        color: #2B2E30;
        margin: 0;
    }
    
    .Button-New-Card{
        margin-top: 5%;
    }

    .Button-New-Card button {
        float: right;
        border: none;
        background-color: transparent;
        color: #001CAA;
        margin-top: -2%;
        letter-spacing: 1px;
        font-size: 1.2vw;
        font-weight: 600;
    }

    .Button-New-Card button:hover{
        color: #0b1b68;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
    }

}