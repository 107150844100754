@media (max-width: 767px){ 

  .NotFoundContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .NotFoundImg{
    width: 25%;
    height: 25%;
  }

  .NotFoundContainer h3{
    margin-top: 10%;
    margin-bottom: 10%;

  }

  .NotFoundContainer span{
    color:#001CAA;
    font-weight: 600;
  }

}

@media (min-width: 768px) and (max-width: 1023px){ 

 .NotFoundContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .NotFoundImg{
    width: 20%;
    height: 20%;
  }

  .NotFoundContainer h3{
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .NotFoundContainer span{
    color:#001CAA;
    font-weight: 600;
  } 

}

@media (min-width: 1024px){

  .NotFoundContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .NotFoundImg{
    width: 15%;
    height: 15%;
  }

  .NotFoundContainer h3{
    margin-top: 2%;
  }

  .NotFoundContainer span{
    color:#001CAA;
    font-weight: 600;
  }

}

