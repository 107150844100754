@media (max-width: 767px) {  
    
    .navbar-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99;
        display: none;
    }

    .navbar-backdrop.open {
        display: block;
        cursor: pointer;
    }

    .navbar-custom {
        position: fixed;
        align-items: center;
        width: 100%;
        min-height: 5vh;
        z-index: 100;
        color: black !important;
        font-weight: bold;
        background-color: #001CAA  !important;
        letter-spacing: 1px !important;
        height: auto;
        padding-top: 4%;
        padding-bottom: 4%;
        
    }

    .Navbar-logo{
        width: 30vw;
        margin-left: 2%;
        cursor: pointer;
        z-index: 10000;
    }
    

    .Toggle{
        border: none;
        display: flex;
        justify-content: right;
        width: 100%;
        outline: none !important;
        margin-top: -17%;
    }

    .Toggle:active{
        outline:  none !important;
    }

    .navbar-toggler:focus{
        box-shadow: none !important;
    }

    .navbar-toggler-icon{
        width: 10%;
        height: 5vh;
        background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }

    .me-auto{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10% 0;
        order: 2;
    }

    .product-link {
        margin-right: 0 !important;
        margin-left: 0% !important;
        margin-top: 5% !important;
        margin-bottom: 0% !important;
        color: #001CAA !important;
        background-color: #ffffff;
        border: 3px solid #ffffff;
        border-radius: 5px;
        width: 70vw;
        display: flex;
        justify-content: center;
        font-size: 3.2vw;
    }

    .product-link:hover{
        background-color:transparent;
        border: 3px solid #ffffff;
        color: #ffffff !important;
        border-radius: 5px;
    }

    .UserNavButton-Container{
        order: 1;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: right;
        align-items: center;
        margin-bottom: -5%;
        margin-right: 0%;
        margin-top: 5%;
    }

    .UserNavButton{
        border: none;
        background-color: transparent;
        min-width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: right;
        align-items: center;
    }
    .UserNavButton img{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 3px solid #001CAA;
    }    

    .UserNavButton p {
        min-width: 30vw;
        font-weight: 600;
        margin-bottom: 0%;
    }

}


@media (min-width: 768px) and (max-width: 991px) {

    .navbar-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 99;
        display: none;
    }

    .navbar-backdrop.open {
        display: block;
    }

    .navbar-custom {
        position: fixed;
        align-items: center;
        width: 100%;
        min-height: 5vh;
        z-index: 100;
        color: black !important;
        font-weight: bold;
        background-color: #001CAA !important;
        letter-spacing: 1px !important;
        height: auto;
        padding: 2% 0% ;
        
    }

    .Navbar-logo{
        width: 30vw;
        margin-left: 2%;
        cursor: pointer;
        z-index: 100000;
    }


    .Toggle{
        border: none;
        display: flex;
        justify-content: right;
        width: 100%;
        outline: none !important;
        margin-top: -18%;
    }

    .Toggle:active{
        outline:  none !important;
    }

    .navbar-toggler:focus{
        box-shadow: none !important;
    }

    .navbar-toggler-icon{
        width: 5.5vw;
        height: 5vh;
        background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='white' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");

    }

     .me-auto{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5% 0;
        order: 2;
    }

    .product-link {
        margin-right: 0 !important;
        margin-left: 0% !important;
        margin-top: 5% !important;
        margin-bottom: 0% !important;
        color: #001CAA !important;
        background-color: #ffffff;
        border: 3px solid #ffffff;
        border-radius: 5px;
        width: 40vw;
        text-align: center;        
        font-size: 2vw;
    }

    .product-link:hover{
        background-color:transparent;
        border: 3px solid #ffffff;
        color: #ffffff !important;
        border-radius: 5px;
    }

    .UserNavButton-Container{
        order: 1;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: right;
        align-items: center;
        margin-bottom: -5%;
        margin-top: 0%;
    }

    .UserNavButton{
        border: none;
        background-color: transparent;
        width: 15%;
        display: flex;
        flex-direction: column;
        justify-content: right;
        align-items: center;
    }
    .UserNavButton img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        border: 3px solid #001CAA;
    }

    .UserNavButton p {
        min-width: 20vw;
        font-weight: 600;
        margin-bottom: 0%;
    }
}

@media (min-width: 992px) {


    .navbar-custom {
        position: fixed;
        width: 100%;
        z-index: 100;
        background-color: #001CAA !important;
        color: #001CAA !important;
        height: 15vh;
        padding-top: 2.4%;
        padding-bottom: 0.5%;
        letter-spacing: 1.5px !important;
        font-weight: 700 !important;
    }

    .Navbar-Container{    
        margin-top: -2%;
        height: 15vh;
        min-width: 40%;
        margin-right: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navbar-collapse {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .Navbar-logo{
        width:13vw;
        margin-left: 4.5%;
        margin-top: -2%;
        z-index: 100000;
        cursor: pointer;
    }

    .me-auto{
        order: 1;
        width: 100%;
        height: 15vh;
        display: flex;
        justify-content: right !important;
        align-items: center !important;
    }

    .product-link {
        margin-right: 5% !important;
        margin-left: 0% !important;
        margin-top: 0 !important;
        color: #ffffff !important;
        border-radius: 5px;
        font-size: 1.1vw;
        font-weight: 700;
    }

    .product-link:hover{
        background-color:transparent;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
        color: #ffffff !important;
    }
}