@font-face {
  font-family: 'RoundedMplus1c';
  src: url('./Assets/fonts/RoundedMplus1c/RoundedMplus1c-Thin.ttf') format('truetype');
  font-weight: 100; /* Muy delgado */
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url('./Assets/fonts/RoundedMplus1c/RoundedMplus1c-Light.ttf') format('truetype');
  font-weight: 300; /* Ligero */
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url('./Assets/fonts/RoundedMplus1c/RoundedMplus1c-Regular.ttf') format('truetype');
  font-weight: 400; /* Normal */
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url('./Assets/fonts/RoundedMplus1c/RoundedMplus1c-Medium.ttf') format('truetype');
  font-weight: 500; /* Medio */
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url('./Assets/fonts/RoundedMplus1c/RoundedMplus1c-Bold.ttf') format('truetype');
  font-weight: 700; /* Negrita */
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url('./Assets/fonts/RoundedMplus1c/RoundedMplus1c-ExtraBold.ttf') format('truetype');
  font-weight: 800; /* Extra Negrita */
  font-style: normal;
}

@font-face {
  font-family: 'RoundedMplus1c';
  src: url('./Assets/fonts/RoundedMplus1c/RoundedMplus1c-Black.ttf') format('truetype');
  font-weight: 900; /* Negra */
  font-style: normal;
}

@font-face {
  font-family: 'CorporateSW10';
  src: url('./Assets/fonts/CorporateSW10/CorporateSW10-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CorporateSW10';
  src: url('./Assets/fonts/CorporateSW10/CorporateSW10-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'CorporateSW10';
  src: url('./Assets/fonts/CorporateSW10/CorporateSW10-Md.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'CorporateSW10';
  src: url('./Assets/fonts/CorporateSW10/CorporateSW10-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'CorporateSW10';
  src: url('./Assets/fonts/CorporateSW10/CorporateSW10-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'CorporateSW10';
  src: url('./Assets/fonts/CorporateSW10/CorporateSW10-Bolder.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}



.App {
  text-align: center;
  font-family: 'CorporateSW10', sans-serif;
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
