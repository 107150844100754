@media (max-width: 767px){
  
  .show-buttons {
    display: block;
  }

  .Landing-Component{
    padding-top: 18%;
    width: 100vw;
    height: 100vh;
    letter-spacing: 1px;
    overflow: hidden;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Landing-Container{
    width: 90vw;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
  }

  .information-container {
      width: 100%;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
  }


  .Title-container{
      align-items: center;
      width: 100%;
  }


  .Title{
      text-align: center;
      font-weight: 800;
      font-size: 9vw;
      color: #2b2e30;
      font-family: 'RoundedMplus1c', sans-serif;
  }

  .Title span{
      color: #001CAA;
  }

  .information-container p {
      margin-top: 10%;
      text-align: center;
      padding: 0%;
      font-weight: 600;
      font-size: 4.5vw;
  }

  .ButtonStartLanding-Content{
    margin: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .ButtonStartLanding{
    background-color: #001CAA;
    border: 3px solid #001CAA;
    margin-top: 5%;
    border-radius: 10px;
    padding: 2% 4%;
    color: #FFF4EC;
    font-weight: 700;
    font-size: 3.5vw;
    letter-spacing: 1px;
  }

  .ButtonStartLanding:hover{
    background-color: rgba(255, 255, 255, 0);
	  border: 3px solid #001CAA;
	  color: #001CAA;
  }

  .img-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 10%;
    margin-right: 5%;
  }


  .img-landing-1{
  position: relative;
  width: 55%;
  overflow: hidden;
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 30% 50%);
  }

  .img-landing-2{
  position: relative;
  width: 70%;
  overflow: hidden;
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 30% 50%);
  }

  .img-landing-3{
  position: relative;
  width: 75%;
  overflow: hidden;
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 30% 50%);
  }

  .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  }


}



@media (min-width: 768px) and (max-width: 1023px) {
  
  .show-buttons {
    display: block;
  }

  .Landing-Component{
    padding-top: 0%;
    width: 100%;
    height: 100vh;
    letter-spacing: 1px;
    overflow: hidden;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Landing-Container{
    margin-top: 18%;
    width: 90vw;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
  }

  .information-container {
      width: 100%;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
  }


  .Title-container{
      align-items: center;
      width: 100%;
  }


  .Title{
      text-align: center;
      font-weight: 800;
      font-size: 5.5vw;
      color: #2b2e30;
      width: 100%;        
      font-family: 'RoundedMplus1c', sans-serif;
  }

  .Title span{
      color: #001CAA;
  }

  .information-container p {
    width: 100%;
    margin-top: 5%;
    text-align: center;
    padding: 0%;
    font-weight: 600;
    font-size: 2.5vw;
    text-align:center;
  }

  .ButtonStartLanding-Content{
    margin: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .ButtonStartLanding{
    background-color: #001CAA;
    border: 3px solid #001CAA;
    margin-bottom: 5%;
    border-radius: 10px;
    padding: 2% 4%;
    color: #FFF4EC;
    font-weight: 700;
    font-size: 2vw;
    letter-spacing: 1px;
  }

  .ButtonStartLanding:hover{
    background-color: rgba(255, 255, 255, 0);
	  border: 3px solid #001CAA;
	  color: #001CAA;
  }

  .img-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 10%;
    margin-right: 4%;
  }


  .img-landing-1{
  position: relative;
  width: 25%;
  overflow: hidden;
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 30% 50%);
  }

  .img-landing-2{
  position: relative;
  width: 30%;
  overflow: hidden;
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 30% 50%);
  }

  .img-landing-3{
  position: relative;
  width: 32%;
  overflow: hidden;
  clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 30% 50%);
  }

  .img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  }

}


@media (min-width: 1024px) {

    .show-buttons {
        display: block;
      }

    .Landing-Component{
        padding-top: 8%;
        width: 100%;
        height: 100vh;
        letter-spacing: 1px;
        overflow: hidden;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Landing-Container{
      margin-left: 4.5%;
      margin-right: 4.5%;
      width: 100%;
      padding: 2%;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: left;
    }

    .information-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    .Title-container{
        width: 50%;
        align-items: center;
    }
    

    .Title{
        margin-top: -10%;
        text-align: left;
        margin-left: -0.4%;
        font-weight: 800;
        font-size: 3.5vw;
        color: #2b2e30;        
        font-family: 'RoundedMplus1c', sans-serif;
    }

  .Title span{
    color: #001CAA;
  }

  .information-container p {
    text-align: left;
    margin-top: 2%;
    margin-bottom: 2.5%;
    margin-left: 0%;
    font-weight: 600;
    font-size: 1.3vw;
    margin-right: 23%;
  }

  .ButtonStartLanding-Content{
    margin: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .ButtonStartLanding{
    background-color: #001CAA;
    border: 3px solid #001CAA;
    margin-bottom: 5%;
    border-radius: 15px;
    padding: 1% 2%;
    color: #FFF4EC;
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
  }

  .ButtonStartLanding:hover{
    background-color: rgba(255, 255, 255, 0);
	  border: 3px solid #001CAA;
	  color: #001CAA;
  }

  .img-container {
    min-height: 50vh;
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0%;
  }
  

  .img-landing-1{
    position: relative;
    width: 25%;
    overflow: hidden;
    clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 30% 50%);
  }

  .img-landing-2{
    position: relative;
    width: 30%;
    overflow: hidden;
    clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 30% 50%);
  }

  .img-landing-3{
    position: relative;
    width: 32%;
    overflow: hidden;
    clip-path: polygon(15% 0%, 85% 0%, 100% 50%, 85% 100%, 15% 100%, 30% 50%);
  }

  .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  


}