@media (max-width: 767px){ 

.Loading{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .Load1{
        width: 15%;
        height: 15%;
        margin: 0 0px;
        border-radius: 2px;
        display: inline-block;
        transform:rotate(20deg);
        animation-name: dots;
        animation-duration:1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    .Load2{
        width: 14%;
        height: 14%;
        margin-left: 3%;
        margin-right: 2%;
        border-radius: 2px;
        display: inline-block;
        transform:rotate(20deg);
        animation-name: dots;
        animation-duration:1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.2s;
    }

    .Load3{
        width: 15%;
        height: 15%;
        margin: 0 0px;
        border-radius: 2px;
        display: inline-block;
        transform:rotate(20deg);
        animation-name: dots;
        animation-duration:1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.4s;
    }

}

@media (min-width: 768px) and (max-width: 1023px){ 

.Loading{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .Load1{
        width: 10%;
        height: 10%;
        margin: 0 0px;
        border-radius: 2px;
        display: inline-block;
        transform:rotate(20deg);
        animation-name: dots;
        animation-duration:1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    .Load2{
        width: 9%;
        height: 9%;
        margin-left: 3%;
        margin-right: 2%;
        border-radius: 2px;
        display: inline-block;
        transform:rotate(20deg);
        animation-name: dots;
        animation-duration:1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.2s;
    }

    .Load3{
        width: 10%;
        height: 10%;
        margin: 0 0px;
        border-radius: 2px;
        display: inline-block;
        transform:rotate(20deg);
        animation-name: dots;
        animation-duration:1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.4s;
    }

}


@media (min-width: 1024px){

    .Loading{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .Load1{
        width: 7%;
        height: 7%;
        margin: 0 0px;
        border-radius: 2px;
        display: inline-block;
        transform:rotate(20deg);
        animation-name: dots;
        animation-duration:1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    .Load2{
        width: 6%;
        height: 6%;
        margin-left: 3%;
        margin-right: 2%;
        border-radius: 2px;
        display: inline-block;
        transform:rotate(20deg);
        animation-name: dots;
        animation-duration:1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.2s;
    }

    .Load3{
        width: 7%;
        height: 7%;
        margin: 0 0px;
        border-radius: 2px;
        display: inline-block;
        transform:rotate(20deg);
        animation-name: dots;
        animation-duration:1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
        animation-delay: 0.4s;
    }

}


@keyframes dots{
    50%{
        opacity: 50%;
        transform: rotate(15deg);
    }
    80%{
        opacity: 80%;
        transform: rotate(5deg);
    }
}