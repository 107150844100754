@media (max-width: 767px){

    .Whatsapp-container {
        position: fixed;
        bottom: 2%;
        right: 2%;
        cursor: pointer;
        z-index: 999999999;
        overflow: visible;
    }
    
    .Whatsapp-Logo {
        width: 50px;
        height: auto;
    } 

}


@media only screen and (min-width: 768px) and (max-width: 1023px){

    .Whatsapp-container {
        position: fixed;
        bottom: 2%;
        right: 2%;
        cursor: pointer;
        z-index: 999999999;
        overflow: visible;
    }
    
    .Whatsapp-Logo {
        width: 60px;
        height: auto;
    }
}

@media (min-width: 1024px){

   .Whatsapp-container {
       position: fixed;
       float: right;
       bottom: 2%;
       right: 1%;
       cursor: pointer;
       z-index: 999999999;
       overflow: visible;
   }
   
   .Whatsapp-Logo {
       width: 60px;
       height: auto;
   }

}
