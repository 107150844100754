body.modal-open {
  height: 100vh !important;
  width: 100vw !important;
  overflow-y: scroll !important;
}

.SuccessPopUp-Header {
    margin: 0% 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
  .SuccessPopUp-Header h2 {
    font-size: 20px;
    font-weight: 700;
    color: #001CAA;
    margin-top: 5%;
  }
  
  .Close-ButtonSuccessPopup {
    margin-left: 90%;
    font-size: 50px;
    color: #001CAA;
  }
  
  .Close-ButtonSuccessPopup:hover{
    color: #2B2E30;
  }
  
  .SuccessPopUp-Body{
    margin: 5% 5% 2% 5%;
  }
  
  .SuccessPopUp-Body p {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #2B2E30;
  }
  
  .SuccessPopUp-Body span{
    color: #001CAA;
  }
  
  
  
  @media (min-width:790px){
    
    body.modal-open {
      height: 100vh !important;
      width: 100vw !important;
      overflow-y: scroll !important;
    }
    
    .SuccessPopUp-Header {
      margin: 0% 5%;
      float: right;
    }
  
    .SuccessPopUp-Header h2 {
      font-size: 20px;
      font-weight: 600;
      color: #001CAA;
      margin-top: 5%;
    }
  
    .Close-ButtonSuccessPopup {
      margin-left: 0%;
      font-size: 35px;
      padding: 0% ;
      font-weight: 700;
      color: #001CAA;
    }
  
    .Close-ButtonSuccessPopup:hover{
      color: #2B2E30;
    }
  
    .SuccessPopUp-Body{
      margin: 8% 0% 5% 0%;
    }
  
    .SuccessPopUp-Body p {
      text-align: center;
      font-size: 25px;
      letter-spacing: 1px;
      font-weight: 700;
      color: #2B2E30;
    }
  
    .SuccessPopUp-Body span{
      color: #001CAA;
    }
  
}