.Filter-Nav-Component {
  display: none;
}


@media (max-width: 767px){

  .Ourcars-Components{
    min-height: 100vh;
    width: 100vw;
    padding: 5% 0;
    padding-top: 25%;
    background-color: #f7f7ff;
    display: flex;
    justify-content: center;
    text-align: center;
  }  

  .Ourcars-Container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f7f7ff;
    min-height: 70vh;
    width: 90vw;
    border-radius: 20px;
    padding: 1% 2%;
  }

  .searchbar-container{
    position: absolute;
    top: 24%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .searchbaricon{
      font-size: 7vw;
      margin-top: 0.2%;
      margin-right: 1%;
      margin-right: 5px;
      color:#001CAA;
  }

  .searchBar{
      min-width: 35vh;
      outline: none;
      border: none;
      border-bottom: 3px solid #001CAA;
      color: #2B2E30;
      font-weight: 600;
      background-color: #f7f7ff;
      font-size: 3.5vw;
  }

  .cleanSearchButton{
    position: absolute;
      color: #001CAA;
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 3.5vw;
  }

  .Information-OurCars-Container{
    padding: 5% 0%;
  }

  .Title-OurCars{
    color: rgb(50, 50, 50);
    font-weight: bolder;
    font-size: 8vw;

  }

  .Title-OurCars span{
    color:#001CAA;
  }

  .OurCars-loading-container{
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .OurCars-not-found{
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Ourcars-Grid-Container{
    display: grid;
    padding-top: 40%;
    padding-bottom: 10%;
    justify-content: start;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    min-height: 72vh;
  }

  .OurCarsCards{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

  }

  /* -------------------------Filter Menu-------------------------- */


  .Filter-Nav-Component{
    display: block;
    position: fixed;
    background-color: #001CAA;
    width: 100vw;
    padding: 4% 0%;
    margin-top: -2%;
    box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.368);
    z-index: 20;
  }

  .filter-Nav-Container{
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 5%;   
  }

  .filter-Nav-Container button{
    background-color: transparent;
    border: none;
    color: #ffffff;
  }

  .filter-menu-backdrop {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.632);
    z-index: 21;
  }

  .Close-Filter-Menu-Container{
    padding: 5% 0;
    margin-top: -5%;
  }

  .Close-Backdrop-Button{
    color: #001caa;
    background-color: transparent;
    border: none;
    margin-top: -3.5%;
    float: right;
  }
  
  .filter-menu {
    position: fixed;
    padding: 5% 4%;
    width: 100vw;
    height: 60%;
    background-color: #ffffff;
    bottom: -5000px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 22;    
    transition: bottom 0.3s ease;
  }

  .filter-menu.show {
    bottom: 0;
    height: 60%;
    overflow: hidden;
    padding-bottom: 4%;
  }

  .filter-menu-handle button{
    display: none;
  }

  .filter-menu-content {
    padding: 0% 8%;
    overflow-y: scroll !important;
  }
  
  .filter-menu-Order-Container{
    width: 100%;
    text-align: left;
    padding-left: 4%;
  }

  .Ordenar-Title-Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Close-Filter-Button{
    color: #001CAA;
    background-color: transparent;
    border: none;
  }

  .Ordenar-Title-Container h5{
    font-size: 4.5vw;
    font-weight: 600;
    color:#001caa;
  }

  .Ordenar-Title-Container div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .Ordenar-Title-Container button:hover {
    background-color: transparent;
    border: none;
    color:#0053c8;
    text-decoration: underline;
  }

  .Order-group {
    padding-right: 5%;
    padding-bottom: 5%;
    margin: 10% 0%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column; 
    border-bottom: 1px solid rgb(201, 201, 201);
  }
  
  .Order-group p {
    cursor: pointer;
    font-weight: bold;
    color: #919191;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .Select-Order{
    background-color: white;
    border: none;
    color:#32333b

  }

  .Order-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Order-buttons button{
    margin: 2% 1%;
    background-color: white;
    border: 2px solid #919191;
    color: #222332;
    border-radius: 20px;
    padding: 2% 0%;
    width: 45%;
    font-size: 3.5vw;
  }

  .Order-buttons button:hover {
    background-color: #dedede;
  }

  .Order-buttons button.active {
    border: 2px solid #0053c8;
    background-color: #00aaff24;
    color: #0053c8;
  }

  .filter-menu-Filter-Container{
    width: 100%;
    text-align: left;
    padding-left: 4%;
  }

  .Filter-Title-Container{
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .Filter-Title-Container h5{
    font-size: 4.5vw;
    font-weight: 600;
    color:#001caa;
  }

  .Filter-Title-Container div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .Filter-Title-Container button{
    background-color: transparent;
    border: none;
    color:#2d84ff;
    font-size: 3.5vw;
    font-weight: 500;
  }

  .Filter-Title-Container button:hover {
    background-color: transparent;
    border: none;
    color:#0053c8;
    text-decoration: underline;
  }

  .checkbox-container{
    padding: 10% 0%;
  }

  .checkbox-container p{
    color: rgb(151, 151, 151);
    font-size: 3.5vw;
    font-weight: 500;
    margin-top: 2%;
    margin-bottom: -5%;

  }

  .checkbox-Label-container{
    padding: 10% 0%;
    border-bottom: 1px solid rgb(201, 201, 201);
  }

  .filter-group {
    padding-right: 5%;
    padding-bottom: 5%;
    margin: 10% 0%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column; 
    border-bottom: 1px solid rgb(201, 201, 201);
  }
  
  .filter-group p {
    cursor: pointer;
    font-weight: bold;
    color: #919191;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filter-group select,
  .filter-group input[type="checkbox"] {
    margin-top: 10px;
  }

  .Marcas-Checkbox{
    display: flex;
    flex-direction: column;
    padding: 5% 0%;
    width: 100%;
  }

  .Marcas-Checkbox label{
    color:#2d84ff;
    padding: 2% 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }

  .Transmision-Checkbox{
    display: flex;
    flex-direction: column;
    padding: 5% 0%;
    width: 100%;
  }

  .Transmision-Checkbox label{
    color:#2d84ff;
    padding: 2% 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }

  .Puertas-Checkbox{
    display: flex;
    flex-direction: column;
    padding: 5% 0%;
    width: 100%;
  }

  .Puertas-Checkbox label{
    color:#2d84ff;
    padding: 2% 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
  }

  .Show-Results-button-Div{
    width: 100%;
    padding: 4% 0%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-top:  1px solid rgb(201, 201, 201);
  }

  .Show-Results-button-Div button{
    background-color: #001caa;
    padding: 2% 3%;
    color: white;
    border: 2px solid #001CAA;
    border-radius: 10px;
    font-size: 3.7vw;
  }

  .Show-Results-button-Div button:hover{
    background-color: white;
    color: #001caa;
    border: 2px solid #001CAA;
  }

  .clear-filters-button{
    background-color: transparent !important;
    border: none !important;
    color: #2d84ff !important;
    font-size: 3.5vw  !important;
    font-weight: 500 !important;
  }

}

@media (min-width: 768px) and (max-width: 1023px){ 

  .Ourcars-Components{
    min-height: 100vh;
    width: 100vw;
    padding: 5% 0;
    padding-top: 25%;
    background-color: #f7f7ff;
    display: flex;
    justify-content: center;
    text-align: center;
  }  

  .Ourcars-Container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
    min-height: auto;
    width: 90vw;
    border-radius: 20px;
    padding: 1% 2%;
  }

  .searchbar-container{
    display: flex;
    align-items: start;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 2%;
  }

  .searchbaricon{
      font-size: 3.5vw;
      margin-top: 0.2%;
      margin-right: 1%;
      margin-right: 5px;
      color:#001CAA;
  }

  .searchBar{
      width: 30vh;
      outline: none;
      border: none;
      border-bottom: 3px solid #001CAA;
      color: #2B2E30;
      font-weight: 600;
      background-color: #f7f7ff;
      font-size: 2vw;
  }

  .cleanSearchButton{
    position: absolute;
      color: #001CAA;
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 2vw;
  }

  .Information-OurCars-Container{
    padding: 5% 0%;
  }

  .Title-OurCars{
    color: rgb(50, 50, 50);
    font-weight: bolder;
    font-size: 5vw;

  }

  .Title-OurCars span{
    color:#001CAA
  }

  .OurCars-loading-container{
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .OurCars-not-found{
    min-height: 61.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Ourcars-Grid-Container{
    display: grid;
    padding-top: 8%;
    padding-bottom: 10%;
    margin-top: 0;
    align-items: self-start;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    min-height: 61.5vh;
  }

  .OurCarsCards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  /* -------------------------Filter Menu-------------------------- */

  .Filter-Nav-Component{
    display: block;
    position: fixed;
    background-color: #001CAA;
    width: 100vw;
    padding: 2% 0%;
    margin-top: -6%;
    box-shadow: 1px 1px 10px rgba(60, 60, 60, 0.368);
    z-index: 20;
  }

  .filter-Nav-Container{
    width: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    padding-right: 5%;   
  }

  .filter-Nav-Container button{
    background-color: transparent;
    border: none;
    color: #ffffff;
  }

  .filter-menu-backdrop {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.632);
    z-index: 21;
  }

  .Close-Filter-Menu-Container{
    padding: 5% 0;
    margin-top: -4%;
  }

  .Close-Backdrop-Button{
    color: #001caa;
    background-color: transparent;
    border: none;
    margin-top: -5%;
    float: right;
  }
  
  .filter-menu {
    position: fixed;
    padding: 5% 4%;
    width: 100vw;
    height: 50%;
    background-color: rgb(255, 255, 255);
    bottom: -5000px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 22;   
    transition: bottom 0.3s ease;
  }

  .filter-menu.show {
    bottom: 0;
    height: 50%;
    overflow: hidden;
    padding-bottom: 4%;
  }

  .filter-menu-handle button{
    display: none;
  }

  .filter-menu-content {
    padding: 0% 8%;
    overflow-y: scroll !important;
  }
  
  .filter-menu-Order-Container{
    width: 100%;
    text-align: left;
    padding-left: 4%;
  }

  .Ordenar-Title-Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Close-Filter-Button{
    color: #001CAA;
    background-color: transparent;
    border: none;
  }

  .Ordenar-Title-Container h5{
    font-size: 2.5vw;
    font-weight: 600;
    color:#001caa;
  }

  .Ordenar-Title-Container div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .Ordenar-Title-Container button:hover {
    background-color: transparent;
    border: none;
    color:#0053c8;
    text-decoration: underline;
  }

  .Order-group {
    padding-right: 1%;
    padding-bottom: 1%;
    margin: 5% 0%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column; 
    border-bottom: 1px solid rgb(201, 201, 201);
  }
  
  .Order-group p {
    cursor: pointer;
    font-weight: bold;
    color: #919191;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .Select-Order{
    background-color: white;
    border: none;
    color:#32333b

  }

  .Order-buttons{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }

  .Order-buttons button{
    margin: 2% 1%;
    background-color: white;
    border: 2px solid #919191;
    color: #222332;
    border-radius: 20px;
    padding: 1% 0%;
    width: 30%;
    font-size: 2vw;
  }

  .Order-buttons button:hover {
    background-color: #dedede;
  }

  .Order-buttons button.active {
    border: 2px solid #0053c8;
    background-color: #00aaff24;
    color: #0053c8;
  }

  .filter-menu-Filter-Container{
    width: 100%;
    text-align: left;
    padding-left: 4%;
  }

  .Filter-Title-Container{
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .Filter-Title-Container h5{
    font-size: 2.5vw;
    font-weight: 600;
    color:#001caa;
  }

  .Filter-Title-Container div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .Filter-Title-Container button{
    background-color: transparent;
    border: none;
    color:#2d84ff;
    font-size: 2vw;
    font-weight: 500;
  }

  .Filter-Title-Container button:hover {
    background-color: transparent;
    border: none;
    color:#0053c8;
    text-decoration: underline;
  }

  .checkbox-container{
    padding: 10% 0%;
  }

  .checkbox-container p{
    color: rgb(151, 151, 151);
    font-size: 2vw;
    font-weight: 500;
    margin-top: 2%;
    margin-bottom: -5%;

  }

  .checkbox-Label-container{
    padding: 10% 0%;
    border-bottom: 1px solid rgb(201, 201, 201);
  }

  .filter-group {
    padding-right: 1%;
    padding-bottom: 1%;
    margin: 5% 0%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column; 
    border-bottom: 1px solid rgb(201, 201, 201);
  }
  
  .filter-group p {
    cursor: pointer;
    font-weight: bold;
    color: #919191;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filter-group select,
  .filter-group input[type="checkbox"] {
    margin-top: 10px;
  }

  .Marcas-Checkbox{
    display: grid;
    padding: 2% 0%;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }

  .Marcas-Checkbox label{
    color:#2d84ff;
    padding: 2% 9%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .Transmision-Checkbox{
    padding: 2% 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 55%;
  }

  .Transmision-Checkbox label {
    color:#2d84ff;
    padding: 2% 9%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .Puertas-Checkbox{
    color:#2d84ff;
    padding: 2% 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .Puertas-Checkbox label {
    color:#2d84ff;
    padding: 2% 9%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .Show-Results-button-Div{
    width: 100%;
    padding: 4% 0%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-top:  1px solid rgb(201, 201, 201);
  }

  .Show-Results-button-Div button{
    background-color: #001caa;
    padding: 1.5% 2.5%;
    color: white;
    border: 2px solid #001CAA;
    border-radius: 10px;
    font-size: 2.1vw;
  }

  .Show-Results-button-Div button:hover{
    background-color: white;
    color: #001caa;
    border: 2px solid #001CAA;
  }

  .clear-filters-button{
    background-color: transparent !important;
    border: none !important;
    color: #2d84ff !important;
    font-size: 2vw  !important;
    font-weight: 500 !important;
  }


}

@media (min-width: 1024px){

  .Ourcars-Components{
    min-height: 100vh;
    width: 100%;
    padding: 5% 0;
    background-color: #f7f7ff;
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    overflow-x: hidden !important;
  }  

  .Ourcars-Container{
    margin-top: 5%;
    min-height: 70vh;
    width: 90vw;
    border-radius: 20px;
    padding: 1% 0%;
    margin-left: 0%;
    margin-right: 5%;
  }

  .searchbar-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
    margin-bottom: 0%;
  }

  .searchbaricon{
      font-size: 2vw;
      margin-top: 0.2%;
      margin-right: 1%;
      margin-right: 5px;
      color:#001CAA;
  }

  .searchBar{
      width: 38vh;
      outline: none;
      border: none;
      border-bottom: 3px solid #001CAA;
      color: #2B2E30;
      font-weight: 600;
      background-color: #f7f7ff;
  }

  .cleanSearchButton{
    position: absolute;
      color: #001CAA;
      background-color: transparent;
      border: none;
      font-weight: 600;
      font-size: 1vw;
  }

  .Information-OurCars-Container{
    padding: 2% 0%;
  }

  .Title-OurCars{
    color: rgb(50, 50, 50);
    font-weight: bolder;
    font-size: 50px;

  }

  .Title-OurCars span{
    color:#001CAA
  }

  .OurCars-loading-container{
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .OurCars-not-found{
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Ourcars-Grid-Container{
    display: grid;
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 3%;
    padding-bottom: 10%;
    justify-content: left;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
  }

  .OurCarsCards{
    display: flex;
    justify-content: center;
  }

  /* -------------------------Filter Menu-------------------------- */

  .Close-Filter-Menu-Container{
    display: none;
  }

  .filter-menu-backdrop {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.632);
    z-index: 21;
    cursor: pointer;
  }
  
  .filter-menu {
    position: fixed;
    padding-left: 5%;
    padding-top:1%;
    padding-bottom: 2%;
    right: -23%;
    width: 28vw;
    height: 100%;
    background-color: #001CAA;
    overflow-y: auto;
    transition: right 0.3s ease;
    display: flex;
    flex-direction: column;
    z-index: 22;
  }

  .filter-menu.show {
    right: 0;
    width: 28vw;
    height: 100%;
    overflow: hidden;
    padding-bottom: 4%;
  }

  .filter-menu-handle button{
    position: absolute;
    margin-top: 10%;
    left: 2%;
    color: #ffffff;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px 0 0 5px;
    transition: 0.3s ease;
    z-index: 1000000;
  }

  .filter-menu-handle:hover {
    color: #000a77;
  }

  .filter-menu-content {
    padding: 1% 5%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .filter-menu-content::-webkit-scrollbar {
    display: none;
  }
  
  .filter-menu-Order-Container{
    width: 100%;
    text-align: left;
    padding-left: 4%;
    padding-top: 8%;
  }

  .filter-menu-content{
    background-color: #ffffff;
    overflow-y: scroll;
    height: 90%;
    margin-left: 2%;

  }

  .Ordenar-Title-Container{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 5%;
  }

  .Ordenar-Title-Container h5{
    font-size: 1.3vw;
    font-weight: 600;
    color:#001caa;
  }

  .Ordenar-Title-Container div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .Ordenar-Title-Container button{
    background-color: transparent;
    border: none;
    color:#2d84ff;
    font-size: 1vw;
    font-weight: 500;
  }

  .Ordenar-Title-Container button:hover {
    background-color: transparent;
    border: none;
    color:#0053c8;
    text-decoration: underline;
  }

  .Order-group {
    padding-right: 5%;
    padding-bottom: 5%;
    margin: 10% 0%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column; 
    border-bottom: 1px solid rgb(201, 201, 201);
  }
  
  .Order-group p {
    cursor: pointer;
    font-weight: bold;
    color: #919191;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .Select-Order{
    background-color: white;
    border: none;
    color:#32333b

  }

  .Order-buttons{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .Order-buttons button{
    margin: 2% 0%;
    background-color: white;
    border: 1px solid #919191;
    color: #919191;
    border-radius: 20px;
    padding: 4%;
    width: 70%;
  }

  .Order-buttons button:hover {
    background-color: #dedede;
  }

  .Order-buttons button.active {
    border: 2px solid #919191;
    background-color: #dedede;
    color: #919191;
  }

  .filter-menu-Filter-Container{
    width: 100%;
    text-align: left;
    padding-left: 4%;
  }

  .Filter-Title-Container{
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .Filter-Title-Container h5{
    font-size: 1.3vw;
    font-weight: 600;
    color:#001caa;
  }

  .Filter-Title-Container div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }

  .Filter-Title-Container button{
    background-color: transparent;
    border: none;
    color:#2d84ff;
    font-size: 1.1vw;
    font-weight: 500;
  }

  .Filter-Title-Container button:hover {
    background-color: transparent;
    border: none;
    color:#0053c8;
    text-decoration: underline;
  }

  .checkbox-container{
    padding: 10% 0%;
  }

  .checkbox-container p{
    color: rgb(151, 151, 151);
    font-size: 1vw;
    font-weight: 500;
    margin-top: 2%;
    margin-bottom: -5%;

  }

  .checkbox-Label-container{
    padding: 10% 0%;
    border-bottom: 1px solid rgb(201, 201, 201);
  }

  .filter-group {
    padding-right: 5%;
    padding-bottom: 5%;
    margin: 10% 0%;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-direction: column; 
    border-bottom: 1px solid rgb(201, 201, 201);
  }
  
  .filter-group p {
    cursor: pointer;
    font-weight: bold;
    color: #919191;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .filter-group select,
  .filter-group input[type="checkbox"] {
    margin-top: 10px;
  }

  .Marcas-Checkbox{
    display: flex;
    flex-direction: column;
    padding: 5% 0%;
    width: 100%;
  }

  .Marcas-Checkbox label{
    color:#2d84ff;
    padding: 2% 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .Transmision-Checkbox{
    display: flex;
    flex-direction: column;
    padding: 5% 0%;
    width: 100%;
  }

  .Transmision-Checkbox label {
    color:#2d84ff;
    padding: 2% 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .Puertas-Checkbox{
    display: flex;
    flex-direction: column;
    padding: 5% 0%;
    width: 100%;
  }

  .Puertas-Checkbox label {
    color:#2d84ff;
    padding: 2% 0%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .Show-Results-button-Div{
    width: 100%;
    padding: 8% 4%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    border-top:  1px solid rgb(201, 201, 201);
    margin-left: 2%;
  }

  .Show-Results-button-Div button{
    background-color: #001caa;
    padding: 2% 3%;
    color: white;
    border: 2px solid #001CAA;
    border-radius: 10px;
    font-size: 1.1vw;
  }

  .Show-Results-button-Div button:hover{
    background-color: white;
    color: #001caa;
    border: 2px solid #001CAA;
  }

  .clear-filters-button{
    background-color: transparent !important;
    border: none !important;
    color: #2d84ff !important;
    font-size: 1vw  !important;
    font-weight: 500 !important;
  }

}