
@media (max-width: 767px) {
    
    .Carscard-container{
        width: 90%;
        min-height: 45vh;
        padding: 1% 5%;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: 1px solid rgb(201, 201, 201);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .Car-Reservado, .Car-Vendido{
        display: flex;
        justify-content: right;
    }

    .Car-Reservado p, .Car-Vendido p{
        border-radius: 25px;
        font-size: 4vw;
        padding: 2% 5%;
        background-color: black;
        color: white;
        position: absolute;
        margin-top: 0%;
        z-index: 2;
    }
    
    .Carscard-Image{
        width: 100%;
        cursor: pointer;
    }
    
    .Carscard-information {
        text-align: left;
        border-bottom: 1px solid rgb(201, 201, 201);
    }
     
    .Carscard-title-h4 {
        font-size: 4.5vw;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .Carscard-information h5{
        font-size: 3.7vw;
        font-weight: 500;
        text-align: left;
    }

    .Precio-Car-Card{
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgb(201, 201, 201);
        padding: 2% 0%;
    }

    .Precio-Car-Card p{
        font-size: 6vw;
        font-weight: 600;
        color: #2B2E30;
        margin: 0;
    }

    .Button-Car-Card{
        margin-top: 0%;
        padding-top: 10%;
    }

    .Button-Car-Card button {
        float: right;
        border: none;
        background-color: transparent;
        color: #001CAA;
        letter-spacing: 1px;
        font-size: 4vw;
        font-weight: 600;
    }

    .Button-Car-Card button:hover{
        color: #0b1b68;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
    }

    .Button-Car-Card button:disabled {
        float: right;
        border: none;
        background-color: transparent;
        color: #aeaeae;
        letter-spacing: 1px;
        font-size: 4vw;
        font-weight: 600;
        text-decoration:line-through;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1023px){

    .Carscard-container{
        width: 90%;
        min-height: 36vh;
        padding: 5%;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: 1px solid rgb(201, 201, 201);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .Car-Reservado, .Car-Vendido{
        display: flex;
        justify-content: right;
    }

    .Car-Reservado p, .Car-Vendido p{
        border-radius: 25px;
        font-size: 2vw;
        padding: 1% 3%;
        background-color: black;
        color: white;
        position: absolute;
        margin-top: -2%;
        z-index: 2;
    }
    
    .Carscard-Image{
        width: 100%;
        cursor: pointer;
    }
    
    .Carscard-information {
        text-align: left;
        border-bottom: 1px solid rgb(201, 201, 201);
    }
     
    .Carscard-title-h4 {
        font-size: 2.3vw;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .Carscard-information h5{
        font-size: 2.1vw;
        font-weight: 500;
        text-align: left;
    }

    .Precio-Car-Card{
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgb(201, 201, 201);
        padding: 2% 0%;
    }

    .Precio-Car-Card p{
        font-size: 3vw;
        font-weight: 600;
        color: #2B2E30;
        margin: 0;
    }

    .Button-Car-Card{
        margin-top: 10%;
    }

    .Button-Car-Card button {
        float: right;
        border: none;
        background-color: transparent;
        color: #001CAA;
        letter-spacing: 1px;
        font-size: 2.2vw;
        font-weight: 600;
    }

    .Button-Car-Card button:hover{
        color: #0b1b68;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
    }

    .Button-Car-Card button:disabled {
        float: right;
        border: none;
        background-color: transparent;
        color: #aeaeae;
        letter-spacing: 1px;
        font-size: 2.2vw;
        font-weight: 600;
        text-decoration:line-through;
    }

}

@media (min-width: 1024px) {
    
    .Carscard-container{
        width: 20vw;
        min-height: 50vh;
        padding: 5%;
        background-color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        border: 1px solid rgb(201, 201, 201);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .Car-Reservado, .Car-Vendido{
        display: flex;
        justify-content: right;
    }

    .Car-Reservado p, .Car-Vendido p{
        border-radius: 20px;
        font-size: 1.1vw;
        padding: 0.5% 1.5%;
        background-color: black;
        color: white;
        position: absolute;
        margin-top: -0%;
        z-index: 2;
    }
    
    .Carscard-Image{
        width: 100%;
        cursor: pointer;
    }
    
    .Carscard-information {
        text-align: left;
        border-bottom: 1px solid rgb(201, 201, 201);
    }
     
    .Carscard-title-h4 {
        padding-top: 5%;
        font-size: 1.3vw;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .Carscard-information h5{
        font-size: 1.1vw;
        font-weight: 500;
        text-align: left;
    }

    .Precio-Car-Card{
        display: flex;
        justify-content: left;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid rgb(201, 201, 201);
        padding: 2% 0%;
    }

    .Precio-Car-Card p{
        font-size: 1.5vw;
        font-weight: 600;
        color: #2B2E30;
        margin: 0;
    }

    .Button-Car-Card{
        margin-top: 5%;
    }

    .Button-Car-Card button {
        float: right;
        border: none;
        background-color: transparent;
        color: #001CAA;
        margin-top: -2%;
        letter-spacing: 1px;
        font-size: 1.1vw;
        font-weight: 600;
    }

    .Button-Car-Card button:hover{
        color: #0b1b68;
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 4px;
    }

    .Button-Car-Card button:disabled {
        float: right;
        border: none;
        background-color: transparent;
        color: #aeaeae;
        letter-spacing: 1px;
        font-size: 1.1vw;
        font-weight: 600;
        text-decoration:line-through;
    }
}