@media (max-width: 767px){ 

    .CarDetail-Component{
        height: 100%;
        width: 100vw;
        background-color: #f7f7ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    .CarDetail-Container {
        background-color: #ffffff;
        width: 90vw;
        border-radius: 20px;
        padding: 5% 0;
        margin-top: 25%;
        border: 1px solid rgb(201, 201, 201);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .Back-Button-Container{
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 0%;
        margin-top: -3%;
    }

    .Back-Button-Container  button{
        background-color: transparent;
        color: #001caa;
        border: none;
        font-size: 8vw;
    }

    .Info-Basica-Container{
        display: flex;
        flex-direction: column;
    }

    .Image-Detail-Slider{
        width: 100%;
    }

    .Slider-Img-Container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5%;
        margin-top: 5%;
        padding-bottom: 20%;
    }

    .Image-Detail-Slider{
        width: 75%;
        background-color: rgb(255, 255, 255);
    }

    .slider-image{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .CarDetail-Img{
        width: 100%;
        cursor: pointer;
    }

    .Expanded-Image-Overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    
    .Expanded-Image-Container {
        position: relative;
        display: flex;
        align-items: center;
        background-color: rgb(255, 255, 255);
        width: 100vw;
        height: 40vh;
    }
    
    .Expanded-Image {
        width: 100%;
        height: 250px;
        object-fit: fill;
    }
    
    .Prev-Button, .Next-Button {
        display: none;
    }
    
    .Prev-Button {
        left: -10%;
    }
    
    .Next-Button {
        right: -10%;
    }
    
    .Close-Button {
        position: absolute;
        top: 0rem;
        left: 1rem;
        background: transparent;
        color: white;
        border: none;
        font-size: 7vw;
        cursor: pointer;
        z-index: 10;
    }
    

    .slick-dots {
        position: absolute;
        bottom: -15%;
        display: flex !important;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .custom-dot {
        width: 10px;
        height: 10px;
        background-color: #d2d2d2;
        border-radius: 50%;
        display: inline-block;
    }
    
    .slick-active .custom-dot {
        background-color: #001caa;
    }

    .Info-Basica-1{
        width: 100%;
        padding: 10% 0%;
        text-align: left;
        margin: 0%;
        border-bottom: 1px solid rgb(201, 201, 201);
        border-top: 1px solid rgb(201, 201, 201);
    }
    
    .CarDetail-Name{
        font-size: 6vw;
        padding: 0% 5%;
        font-weight: 700;
    }

    .Basic-Content-container{
        padding: 0% 5%;
        border-bottom: 1px solid rgb(201, 201, 201);
        display: flex;
        text-align: left;
    }

    .Basic-Content-1{
        margin-top: 5%;
        width: 90%;
        display: flex;
        justify-content: space-between;
        text-align: left;
        font-size: 4vw;
    }

    .Precio-Auto-Container{
        padding: 1% 5%;
        border-bottom: 1px solid rgb(201, 201, 201);
        display: flex;
        justify-content: left;
        align-items: center;
    }

    .Precio-Auto-Container div{
        margin-top: 3%;
        min-width: 70%;
    }

    .Precio-Auto-Container p{
        font-size: 6vw;
        font-weight: 600
    }

    .All-Info-Container{
        padding: 3% 5%;
        border-bottom: 1px solid rgb(201, 201, 201);
    }

    .All-Info-Container h5{
        font-size: 3.9vw;
        font-weight: 600;
        padding-top: 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .All-info-1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
        text-align: center;
    }

    .All-info-1 div{
        padding: 15% 0%;
        border-bottom: 1px solid rgb(201, 201, 201);
    }

    .All-info-1 h6{
        font-size: 3.5vw;
        width: 100%;
        text-align: center;
    }

    .All-info-1 p {
        font-size: 3.6vw;
        font-weight: 600;
        text-align: center;
    }

    .All-info-2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
        text-align: center;
    }

    .All-info-2 div{
        padding: 15% 0%;
    }

    .All-info-2 h6{
        font-size: 3.5vw;
        width: 100%;
        text-align: center;
    }

    .All-info-2 p {
        font-size: 3.6vw;
        font-weight: 600;        
        text-align: center;
    }

    .All-Direc-info-1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
        text-align: left;
    }

    .All-Direc-info-1 div{
        padding: 15% 0%;
    }

    .All-Direc-info-1 h6{
        font-size: 3.5vw;
        width: 100%;
        text-align: center;
    }

    .All-Direc-info-1 p {
        font-size: 3.6vw;
        font-weight: 600;
        text-align: center;
    }
    
    .arrow {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 2px;
        transform: rotate(0deg);
        transition: transform 0.2s ease-in-out;
    }
    
    .arrow.down {
        transform: rotate(45deg);
    }
    
    .arrow.up {
        transform: rotate(-135deg);
    }

    .Consulta-Button-Container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0% 2%;
        padding-top: 10% !important;
        
    }

    .Consulta-Button-Container button {
        background-color: #001caa;
        border: solid 2px #001caa;
        border-radius: 10px;
        padding: 2% 4%;
        color: white;
        font-size: 3.7vw;
        font-weight: 600;
    }

    .Consulta-Button-Container button:hover{
        background-color: white;
        color: #001caa;
        border: solid 2px #001caa;
    }

    .Image-Counter {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background: transparent;
        color: white;
        border: none;
        font-size: 5vw;
        cursor: pointer;
        z-index: 10;
      }
      
      .Dots-Container {
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 8%;
      }
      
      .Dot {
        width: 12px;
        height: 12px;
        background-color: #ccc;
        border-radius: 50%;
        cursor: pointer;
      }
      
      .Dot.active {
        background-color: #001caa;
      }
      

}    

@media (min-width: 768px) and (max-width: 1023px){ 

    .CarDetail-Component{
        min-height: 100vh;
        width: 100%;
        padding: 5% 0;
        background-color: #f7f7ff;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .CarDetail-Container{
        background-color: #ffffff;
        margin-top: 20%;
        min-height: 40vh;
        width: 90vw;
        border-radius: 20px;
        padding: 5%;
        margin-bottom: 20%;
        border: 1px solid rgb(201, 201, 201);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .Back-Button-Container{
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 0%;
        margin-top: -5%;
    }

    .Back-Button-Container  button{
        background-color: transparent;
        color: #001caa;
        border: none;
        font-size: 5vw;
    }

    .Info-Basica-Container{
        display: flex;
        flex-direction: column;
    }

    .Image-Detail-Slider{
        width: 100%;
    }

    .Slider-Img-Container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5%;
        margin-top: 5%;
        padding-bottom: 20%;
    }

    .Image-Detail-Slider{
        width: 75%;
        background-color: rgb(255, 255, 255);
    }

    .slider-image{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .CarDetail-Img{
        width: 100%;
    }

    .Expanded-Image-Overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }
    
    .Expanded-Image-Container {
        position: relative;
        display: flex;
        align-items: center;        
        width: 100vw;
        height: 45vh;
    }
    
    .Expanded-Image {
        width: 100%;
        object-fit: fill;
        background-color: rgb(255, 255, 255);
    }
    
    .Prev-Button, .Next-Button {
        display: none;
    }

    .Prev-Button {
        left: -10%;
    }
    
    .Next-Button {
        right: -10%;
    }
    
    .Close-Button {
        position: absolute;
        top: 0rem;
        left: 1rem;
        background: transparent;
        color: white;
        border: none;
        font-size: 5vw;
        cursor: pointer;
        z-index: 10;
    }
    

    .slick-dots {
        position: absolute;
        bottom: -15%;
        display: flex !important;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
    }

    .custom-dot {
        width: 10px;
        height: 10px;
        background-color: #d2d2d2;
        border-radius: 50%;
        display: inline-block;
    }
    
    .slick-active .custom-dot {
        background-color: #001caa;
    }

    .Info-Basica-1{
        width: 100%;
        padding: 10% 0%;
        border: 1px solid rgb(201, 201, 201);
        border-radius: 20px;
        text-align: left;
        margin: 0;
    }
    
    .CarDetail-Name{
        font-size: 3.5vw;
        padding: 0% 5%;
        font-weight: 700;
    }

    .Basic-Content-container{
        padding: 0% 5%;
        border-bottom: 1px solid rgb(201, 201, 201);
        display: flex;
        text-align: left;
    }

    .Basic-Content-1{
        margin-top: 5%;
        width: 60%;
        display: flex;
        justify-content: space-between;
        text-align: left;
        font-size: 2.4vw;
    }

    .Precio-Auto-Container{
        padding: 1% 5%;
        border-bottom: 1px solid rgb(201, 201, 201);
        display: flex;
        justify-content: left;
        align-items: center;
    }

    .Precio-Auto-Container div{
        margin-top: 2%;
        min-width: 70%;
    }

    .Precio-Auto-Container p{
        font-size: 3vw;
        font-weight: 600
    }

    .All-Info-Container{
        padding: 1% 5%;
        border-bottom: 1px solid rgb(201, 201, 201);
    }

    .All-Info-Container h5{
        font-size: 2vw;
        font-weight: 600;
        padding-top: 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .All-info-1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
        text-align: left;
    }

    .All-info-1 div{
        padding: 5% 0%;
        border-bottom: 1px solid rgb(201, 201, 201);
    }

    .All-info-1 h6{
        font-size: 1.8vw;
    }

    .All-info-1 p {
        font-size: 1.9vw;
        font-weight: 600;
    }

    .All-info-2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
    }

    .All-info-2 div{
        padding: 5% 0%;
    }

    .All-info-2 h6{
        font-size: 1.8vw;
    }

    .All-info-2 p {
        font-size: 1.9vw;
        font-weight: 600;
    }

    .All-Direc-info-1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
        text-align: left;
    }

    .All-Direc-info-1 div{
        padding: 5% 0%;
    }

    .All-Direc-info-1 h6{
        font-size: 1.8vw;
    }

    .All-Direc-info-1 p {
        font-size: 1.9vw;
        font-weight: 600;
    }
    
    .arrow {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 2px;
        transform: rotate(0deg);
        transition: transform 0.2s ease-in-out;
    }
    
    .arrow.down {
        transform: rotate(45deg);
    }
    
    .arrow.up {
        transform: rotate(-135deg);
    }

    .Consulta-Button-Container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0% 2%;
        padding-top: 8% !important;
        
    }

    .Consulta-Button-Container button {
        background-color: #001caa;
        border: solid 2px #001caa;
        border-radius: 10px;
        padding: 2%;
        color: white;
        font-size: 2vw;
        font-weight: 600;
    }

    .Consulta-Button-Container button:hover{
        background-color: white;
        color: #001caa;
        border: solid 2px #001caa;
    }

    .Image-Counter {
        position: absolute;
        top: 1rem;
        right: 1.2rem;
        background: transparent;
        color: white;
        border: none;
        font-size: 2.5vw;
        cursor: pointer;
        z-index: 10;
      }
      
      .Dots-Container {
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 8%;
      }
      
      .Dot {
        width: 12px;
        height: 12px;
        background-color: #ccc;
        border-radius: 50%;
        cursor: pointer;
      }
      
      .Dot.active {
        background-color: #001caa;
      }
      
      

}

@media (min-width: 1024px){

    .CarDetail-Component{
        min-height: 100vh;
        width: 100%;
        padding-top: 4%;
        padding-bottom: 2%;
        background-color: #f7f7ff;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .CarDetail-Container{
        background-color: #ffffff;
        margin-top: 5%;
        min-height: 40vh;
        width: 90vw;
        border-radius: 20px;
        padding: 3% 2%;
        padding-top: 4% !important;
        border: 1px solid rgb(201, 201, 201);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .Back-Button-Container{
        display: flex;
        justify-content: left;
        align-items: center;
        padding-left: 0%;
        margin-top: -3%;
    }

    .Back-Button-Container  button{
        background-color: transparent;
        color: #001caa;
        border: none;
        font-size: 3vw;
    }

    .Info-Basica-Container{
        display: flex;
    }

    .Slider-Img-Container{
        width: 65%;
        display: flex;
        justify-content: center;
        align-items: start;
        margin-right: 0%;
        margin-top: 5%;
    }

    .Image-Detail-Slider{
        width: 60%;
        
    }


    .slider-image{
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .CarDetail-Img{
        width: 100%;
        border-radius: 20px;
        cursor: pointer;
    }


    .Expanded-Image-Overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.8);
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        cursor: pointer;
    }
    
    .Expanded-Image-Container {
        position: relative;
        display: flex;
        align-items: center;
    }
    
    .Expanded-Image {
        width: 100%;
        height: 550px;
        object-fit: contain;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
    }
    
    .Prev-Button, .Next-Button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: transparent;
        color: white;
        border: none;
        font-size: 2.5vw;
        cursor: pointer;
        z-index: 10;
        padding: 0.5rem;
    }
    
    .Prev-Button {
        left: -3rem;
    }
    
    .Next-Button {
        right: -3rem;
    }
    
    .Close-Button {
        position: absolute;
        top: 0.5rem;
        left: 1rem;
        background: transparent;
        color: white;
        border: none;
        font-size: 3vw;
        cursor: pointer;
        z-index: 10;
    }
      
    .Image-Counter {
        position: absolute;
        top: 1.5rem;
        right: 2rem;
        background: transparent;
        color: white;
        border: none;
        font-size: 1.5vw;
        cursor: pointer;
        z-index: 10;
      }
      

    .slick-dots {
        position: absolute;
        bottom: -12%;
        display: flex !important;
        justify-content: space-around !important;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
        left: -19%;
        width: 45vw;
        height: 8vh;
    }

    .dotscontainer{
        gap: 10px;
    }

    .custom-dot {
        width: 35px;
        height: 35px;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        opacity: 0.7;
        transition: opacity 0.3s ease;
        display: flex;
        justify-content: space-around;
        
    }
    
    .custom-dot img {
        width: 100%;
        height: 100%;
        margin: 0 10%;
        object-fit: cover;
    }
    
    .custom-dot.active {
        opacity: 1;
        width: 55px;
        height: 55px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: -45% !important;
        margin-left: -45% !important;
        border: 2px solid #001caa;
    }

    .custom-dot:hover{
        transform: scale(1.1);
    }
    
    .slick-active .custom-dot {
        background-color: #353535;
    }
    
    .Info-Basica-1{
        width: 30%;
        padding: 2% 0%;
        border: 1px solid rgb(201, 201, 201);
        border-radius: 20px;
        text-align: left;
    }
    
    .CarDetail-Name{
        font-size: 2.5vw;
        padding: 0% 5%;
        font-weight: 700;
    }

    .Basic-Content-container{
        padding: 0% 5%;
        border-bottom: 1px solid rgb(201, 201, 201);
        display: flex;
        text-align: left;
    }

    .Basic-Content-1{
        margin-top: 2%;
        min-width: 70%;
        display: flex;
        justify-content: space-between;
        text-align: left;
        font-size: 1.2vw;
    }

    .Precio-Auto-Container{
        padding: 1% 5%;
        border-bottom: 1px solid rgb(201, 201, 201);
        display: flex;
        justify-content: left;
        align-items: center;
    }

    .Precio-Auto-Container div{
        margin-top: 2%;
        min-width: 70%;
    }

    .Precio-Auto-Container p{
        font-size: 1.7vw;
        font-weight: 600
    }

    .All-Info-Container{
        padding: 1% 5%;
        border-bottom: 1px solid rgb(201, 201, 201);
    }

    .All-Info-Container h5{
        font-size: 1.1vw;
        font-weight: 600;
        padding-top: 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .All-info-1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
        text-align: left;
    }

    .All-info-1 div{
        padding: 5% 0%;
        border-bottom: 1px solid rgb(201, 201, 201);
    }

    .All-info-1 h6{
        font-size: .9vw;
    }

    .All-info-1 p {
        font-size: 1vw;
        font-weight: 600;
    }

    .All-info-2{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
    }

    .All-info-2 div{
        padding: 5% 0%;
    }

    .All-info-2 h6{
        font-size: .9vw;
    }

    .All-info-2 p {
        font-size: 1vw;
        font-weight: 600;
    }

    .All-Direc-info-1{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0rem;
        text-align: left;
    }

    .All-Direc-info-1 div{
        padding: 5% 0%;
    }

    .All-Direc-info-1 h6{
        font-size: .9vw;
    }

    .All-Direc-info-1 p {
        font-size: 1vw;
        font-weight: 600;
    }
    
    .arrow {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: solid black;
        border-width: 0 2px 2px 0;
        padding: 2px;
        transform: rotate(0deg);
        transition: transform 0.2s ease-in-out;
    }
    
    .arrow.down {
        transform: rotate(45deg);
    }
    
    .arrow.up {
        transform: rotate(-135deg);
    }
    
    .Consulta-Button-Container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0% 2%;
        padding-top: 5% !important;

    }

    .Consulta-Button-Container button {
        background-color: #001caa;
        border: solid 2px #001caa;
        border-radius: 10px;
        padding: 2%;
        color: white;
        font-size: 1.1vw;
        font-weight: 600;
    }

    .Consulta-Button-Container button:hover{
        background-color: white;
        color: #001caa;
        border: solid 2px #001caa;
    }

    .Expanded-Image-Container {
        position: relative;
        text-align: center;
    }
    
    .Expanded-Image {
        max-width: 100%;
        max-height: 80vh;
        transition: transform 0.3s ease;
    }
    
    .Thumbnail-Container {
        display: flex;
        justify-content: center;
        margin-top: 2%;
        gap: 10px;
    }
    
    .Thumbnail {
        width: 60px;
        height: 60px;
        object-fit: fill;
        cursor: pointer;
        border: 2px solid transparent;
        border-radius: 5%;
        transition: transform 0.3s ease, border 0.3s ease;
    }
    
    .Thumbnail.active {
        border-color: #001caa;
        transform: scale(1.2);
    }
    
    .Thumbnail:hover {
        transform: scale(1.1);
    }
    
      
    .Dots-Container {
        display: flex;
        justify-content: center;
        gap: 8px;
        margin-top: 10px;
    }
      
      .Dot {
        width: 12px;
        height: 12px;
        background-color: #ccc;
        border-radius: 50%;
        cursor: pointer;
      }
      
      .Dot.active {
        background-color: #007bff;
      }
      

}
