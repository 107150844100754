@media (max-width: 767px){
    .Roles-Section{
        background-color: #ffffff;
        padding-top: 2%;
    }

    .Roles-container{
        margin-top: 10%;
        background-color: #001CAA;
        overflow: hidden;
        margin-top: 5%;
        padding-top: 10%;
        padding-bottom: 15%;        
        border-top-left-radius: 40px;
        border-top-right-radius: 40px;
        box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.396);
    }

    .Roles-title{
        margin-left: 9.8%;
        margin-bottom: 20%;
    }

    .Roles-title h2{
        text-align: left;
        font-size: 8vw;
        color: white;
        font-weight: 800;        
      font-family: 'RoundedMplus1c', sans-serif;
    }

    .Roles-title span{
        color: white;
    }

    .Slider-container {
        margin-left: 8%;
        margin-right: 8%;
    }

    .slick-prev,
    .slick-next {
        width: auto !important;
        height: 8% !important;
        color: white !important;
    }

    .RolesCards{
        margin-left: 5%;
        margin-top: 0;
        height: 100%;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1023px){
    
    .Roles-Section{
        background-color: #ffffff;
        padding-top: 2%;
    }

    .Roles-container{
        margin-top: 10%;
        background-color: #001CAA;
        overflow: hidden;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 10%;
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
        box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.396);
    }

    .Roles-title{
        margin-left: 9.8%;
        margin-bottom: 10%;
    }

    .Roles-title h2{
        text-align: left;
        font-size: 5vw;
        color: white;
        font-weight: 800;        
        font-family: 'RoundedMplus1c', sans-serif;
    }

    .Roles-title span{
        color: white;
    }

    .Slider-container {
        margin-left: 8%;
        margin-right: 8%;
      }

    .slick-prev,
    .slick-next {
        width: auto !important;
        height: 8% !important;
        color: white !important;
    }

    .RolesCards{
        margin-left: 5%;
        margin-top: 0;
    }

}

@media (min-width: 1024px) {

    .Roles-Section{
        background-color: #ffffff;
        padding-top: 2%;
    }

    .Roles-container{
        margin-top: 10%;
        background-color: #001CAA;
        overflow: hidden;
        margin-top: 5%;
        padding-top: 5%;
        padding-bottom: 6%;        
        border-top-left-radius: 70px;
        border-top-right-radius: 70px;
        box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.396);
    }

    .Roles-title{
        margin-left: 6.3%;
        margin-bottom: 5%;
    }

    .Roles-title h2{
        text-align: left;
        font-size: 3vw;
        color: white;
        font-weight: 800;        
        font-family: 'RoundedMplus1c', sans-serif;
    }

    .Roles-title span{
        color: white;
    }

    .Slider-container {
        margin-left: 8%;
        margin-right: 8%;
      }

    .slick-prev,
    .slick-next {
        width: auto !important;
        height: 8% !important;
        color: white !important;
    }

    .RolesCards{
        margin-left: 15%;
        margin-top: 0;
    }

}