@media (max-width: 767px){ 
    
    .QuienSomos-Component{
        padding-bottom: 10%;
        width: 100%;
        letter-spacing: 1px;
        overflow: hidden;
        background-color: #f7f7ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .QuienSomos-Container{
        background-color: white;
        width: 100%;
        min-height: 70vh;
        padding-top: 24%;
        padding-bottom: 12%;
        padding-left: 5%;
        padding-right: 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;        
        border-bottom-left-radius: 40px;
        border-bottom-right-radius: 40px;  
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .QuienSomos-Info-Container{
        padding: 0 5%;
        width: 100%;
    }

    .QuienSomos-Info-Container h2{
        font-size: 8vw;
        font-weight: 800;        
        font-family: 'RoundedMplus1c', sans-serif;
    }

    .QuienSomos-Info-Container span{
        color: #001CAA;
    }

    .QuienSomos-Info-Container p{
        margin-top: 8%;
        font-weight: 600;
        font-size: 4.5vw;
        text-align: left;        
    }

    .QuienSomos-Image-Container{
        width: 100%;
        margin-top: 5%;
    }

    .QuienSomos-Image-Container img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 15px;

    }


}

@media only screen and (min-width: 768px) and (max-width: 1023px){

    .QuienSomos-Component{
        padding-bottom: 6%;
        width: 100%;
        letter-spacing: 1px;
        overflow: hidden;
        background-color: #f7f7ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .QuienSomos-Container{
        background-color: white;
        padding-top: 20%;
        padding-bottom: 10%;
        width: 100%;
        min-height: 70vh;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;        
        border-bottom-left-radius: 70px;
        border-bottom-right-radius: 70px;  
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .QuienSomos-Info-Container{
        width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .QuienSomos-Info-Container h2{
        font-size: 5vw;
        font-weight: 800;        
        font-family: 'RoundedMplus1c', sans-serif;
    }

    .QuienSomos-Info-Container span{
        color: #001CAA;
    }

    .QuienSomos-Info-Container p{
        margin-top: 8%;
        font-weight: 600;
        font-size: 2.1vw;
        text-align: justify;        
    }

    .QuienSomos-Image-Container{
        width: 90%;
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
    }

    .QuienSomos-Image-Container img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 15px;

    }

}

@media (min-width: 1024px) {

    .QuienSomos-Component{
        padding-bottom: 12%;
        width: 100%;
        height: 100vh;
        letter-spacing: 1px;
        overflow: hidden;
        background-color: #f7f7ff;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .QuienSomos-Container{
        background-color: #ffffff;
        width: 100vw;
        min-height: 80vh;        
        padding-top: 20%;
        padding-bottom: 2%;
        display: flex;
        justify-content: space-between;
        border-bottom-left-radius: 70px;
        border-bottom-right-radius: 70px;  
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);      
    }

    .QuienSomos-Info-Container{
        padding: 0 5%;
        width: 50%;
        margin-left: 1.5%;
        margin-right: 1.5%;
    }

    .QuienSomos-Info-Container h2{
        font-size: 3vw;
        font-weight: 800;        
        font-family: 'RoundedMplus1c', sans-serif;
    }

    .QuienSomos-Info-Container span{
        color: #001CAA;
    }

    .QuienSomos-Info-Container p{
        margin-top: 8%;
        font-weight: 600;
        font-size: 1.2vw;
        
    }

    .QuienSomos-Image-Container{
        width: 50%;
        padding: 0% 5%;
    }

    .QuienSomos-Image-Container img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 15px;

    }

}